.puzzleCurveWhale {
  caret-color: #0000;
  width: 50px;
  height: 40px;
  background-image: url("mazeWhale.2c7e09dc.gif");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
}

.puzzleCurveWhale:active {
  background-image: url("whaleRun.8d6e6a95.gif");
}

/*# sourceMappingURL=index.9c3afc13.css.map */
