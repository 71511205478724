.puzzleCurveWhale{
    caret-color: transparent;
    position: fixed;
    width: 50px;
    height: 40px;
    background-image: url(../../img/puzzles/mazeWhale.gif);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}

.puzzleCurveWhale:active {
    background-image: url(../../img/puzzles/whaleRun.gif);
}